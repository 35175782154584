.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body{
  background: #fafafa;
}

.ant-form-item-explain{
  font-size: 12px !important;
  line-height: 1.3 !important;
}

/* New styles */
.multi-column-submenu .ant-dropdown-menu-item-group {
  display: inline-block;
  vertical-align: top;
  width: 33.33%;
}

.multi-column-submenu .ant-dropdown-menu-item-group-title {
  width: 100%;
  display: block;
}

.conversation.unread h4{
  color: #007fff !important;
}

.message-list-container ul {
  display: flex;
  flex-direction: column;
}
.message-list {
  max-height: 80vh;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.message {
  max-width: 60%;
  margin: 5px 15px;
  padding: 10px 15px !important;
  border-radius: 15px;
  background-color: #dcf8c6;
}

.message p {
  margin: 0;
}

.message p.message-content {
  white-space: pre-wrap;
}

.message p.date {
  margin-top: 5px;
  font-size: 0.8em;
  color: #666;
  text-align: right;
}

.message.user {
  display: flex;
  align-self: flex-end;
  background-color: #ffffff;
  border: 1px solid #edebeb !important;
}

.message.action {
  background-color: #fde380;
  max-width: 100%;
  border: 1px solid #f7c87e !important;
}

.message.action > div {
  width: 100%;
}

.message.assistant {
  display: flex;
  align-self: flex-start;
  background-color: #ffffff;
  border: 1px solid #edebeb !important;
}

.chat-avatar.assistant .ant-avatar{
  background: #ff8317;
}

.chat-avatar.user .ant-avatar{
  background: #1777ff;
}

.chat-avatar.action{
  display: none;
}

tcxspan {
  display: contents !important;
}
